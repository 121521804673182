/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole{
  & &__item{
    border-top: 1px dotted #ccc;
  }
  & &__actions{
    margin-top: 32px;
    padding-bottom:20px;
    border-bottom: 1px dotted #ccc;
  }
}
.ec-addressList{
  & &__item{
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
    position: relative;
    border-bottom: 1px dotted #ccc;
  }
  & &__remove{
    align-items: center;
    flex-flow: row nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    height: 44px;
    justify-content: center;
    padding: 0;
    text-align: center;
    width: 44px;
    .ec-icon img {
      width: 14px;
      height: 14px;
    }
  }
  & &__address{
    flex-grow: 1;
    flex-shrink: 1;
    padding: 16px 0;
    width: auto;
  }
  & &__action{
    flex-grow: 0;
    flex-shrink: 0;
    width: 50px;
  }
}
