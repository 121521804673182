@import "../mixins/variables";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/media";

@mixin row{
  margin-left:  ceil(math.div(30, -2) * 1px);
  margin-right: floor(math.div(30, -2) * 1px);
  @include clearfix
}

@mixin makeSmColumn($columns){
  position: relative;
  min-height: 1px;
  padding-left:  calc(math.div(30, 2) * 1px);
  padding-right: calc(math.div(30, 2) * 1px);

  @media (min-width: $desktop) {
    float: left;
    width: percentage(math.div($columns, 12));
  }
}

/*
レイアウト

様々なレイアウトを変更する為のスタイル群です。

Styleguide 5.2
*/

/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

画像は20%で表示されます。

ex [注文履歴 ログイン後→注文履歴ボタンを押下](http://demo3.ec-cube.net/mypage)

Markup:
.ec-imageGrid
  .ec-imageGrid__img: img(src="http://demo3.ec-cube.net/upload/save_image/0701113537_559351f959620.jpeg")
  .ec-imageGrid__content
    p.ec-font-bold ホーローマグ
    p ¥ 1,728 x 1

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.2.1
*/
.ec-imageGrid{
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  justify-content: flex-start;
  @include borderTop;
  padding: 10px 0;
  width: 100%;

  & &__img{
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0;
    width: 100px;

    @include media_desktop {
      width: 120px;
    }

    img{
      width: 100%;
    }
  }
  & &__content{
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1.4rem;
    width: auto;

    @include media_desktop {
      font-size: 1.6rem;
    }

    p {
      line-height: 1.6;
      margin-bottom: 0;
      font-size: 1.4rem;

      @include media_desktop {
        font-size: 1.6rem;
      }

      span {
        margin-left: 10px;
      }
    }

    a {
      color: #004e56;
      text-decoration: none;

      @include hover() {
        color: #5CB1B1;
      }
    }
  }
}
