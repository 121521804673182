@import "../mixins/media";
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole{
  @include container;
  & &__actions {
    padding-top:40px;
  }
  p {
    margin:16px 0;
  }

}
.ec-contactConfirmRole {
  @include container;
  & &__actions {
    padding-top:40px;

    &__item {
      margin: 0 0 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .ec-blockBtn--action {
    margin-bottom: 16px;
  }
}
.ec-contactCompleteRole {
  @include container;

  .ec-text-ac {
    margin-bottom: 60px;
  }
}
