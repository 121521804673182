@import "../mixins/projects";
@import "../mixins/media";
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/

/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole{
  & &__navlist {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 1px 0 0 1px;
    margin-bottom: 32px;
    padding: 0;
    list-style: none;
    @include media_desktop {
      flex-wrap: nowrap;
      margin-bottom: 50px;
    }
  }

  & &__item{
    height: 54px;
    width: 50%;
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 0 1px 1px 0;
    text-align: center;
    font-weight: bold;
    @include media_desktop {
      width: math.div(1, 5) * 100%;
    }

    a {
      align-items: center;
      color: #000;
      display: flex;
      flex-flow: row nowrap;
      font-size: 14px;
      height: 100%;
      justify-content: center;
      padding: 6px;
      width: 100%;
      transition: background-color .35s ease, color .35s ease;
      @include media_desktop {
        font-size: 16px;
      }

      @include hover() {
        background-color: #e9ecef;
        color: #004e56;
      }
    }
  }
  .active {
    a {
      color: #004e56;
    }
  }
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg{
  @include mypageContainer;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  border-bottom: 1px solid #D0D0D0;
  // @include borderBottom;

}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole{
  & &__header {
    margin-bottom: 16px;
  }
  & &__detail {
  }
  & &__itemList {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
  }
  & &__item{
    margin-bottom: 8px;
    width: 47.5%;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    &-image {
      margin-bottom: 10px;
      text-align: center;
      padding: 100% 0 0;
      position: relative;
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      width: 25%;
    }
    .ec-closeBtn--circle {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
      .ec-icon img{
        width: 1em;
        height: 1em;
      }
    }
  }
  & &__itemThumb {
    display: block;
    height:auto;
    margin-bottom: 8px;
    transition: opacity .35s ease;

    & img{
      bottom: auto;
      display: block;
      height: 100%;
      left: 0;
      max-height: none;
      object-fit: contain;
      object-position: center;
      position: absolute;
      right: auto;
      top: 0;
      width: 100%;
    }

    @include hover() {
      opacity: .65;
    }
  }
  & &__itemTitle{
    margin-bottom: 2px;
  }
  & &__itemPrice{
    font-weight: bold;
    margin-bottom: 0;
  }

}
