@import "../mixins/forms";
@import "../mixins/media";
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/



/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input{
  @include forms-reset;
  @include form-controls;
  input{
    height: 40px;
    margin-bottom: 10px;
    @include media_desktop {
      margin-bottom: 16px;
    }
  }
  textarea {
    height: auto;
    min-height: 100px;
  }
  p {
    line-height: 1.4;
  }
  .ec-errorMessage {
    margin: 0 0 25px;
    font-size: 12px;
    font-weight: bold;
    color: $clrRed;
  }
}
.error.ec-input {
  input,select,textarea{
    margin-bottom: 5px;
    border-color: #CF3F34;
    background: #FDF1F0;
  }
}

.ec-checkbox,.ec-radio{
  .ec-errorMessage {
    margin-bottom: 25px;
    font-size: 12px;
    font-weight: bold;
    color: $clrRed;
  }
}
.error.ec-checkbox,.error.ec-radio {
  input, label, div{
    border-color: #CF3F34;
    background: #FDF1F0;
  }
}

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput{
  @extend .ec-input;
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  justify-content: flex-start;
  @include media_desktop {
    gap: 20px;
  }

  &__item,
  .customer-form {
    display: block;
    width: calc(50% - 6px);
    @include media_desktop {
      width: calc(50% - 10px);
   }
  }
  // input[type='text']{
  //   display: inline-block;
  //   width: 47%;
  //   margin-left: 2%;
  //   @include media_desktop {
  //     margin-left: 15px;
  //     width: 45%;
  //   }
  // }
  // .ec-errorMessage {
  //   display: inline-block;
  //   width: 47%;
  //   @include media_desktop {
  //     margin-left: 15px;
  //     width: 45%;
  //   }
  // }
  // input[type='text'],
  // .ec-errorMessage {
  //   &:first-of-type {
  //     margin-left: 0;
  //   }
  // }
}
.error.ec-halfInput {
  input,select,textarea{
    margin-bottom: 5px;
  }
}

.error.ec-halfInput__item {
  input,select,textarea{
    margin-bottom: 5px;
    border-color: #CF3F34;
    background: #FDF1F0;
  }
}

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput{
  @extend .ec-input;
  input[type='number']{
    display: inline-block;
    width: auto;
    max-width: 100px;
    text-align: right;
  }
}
/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        img(src='/moc/icon/question-white.svg', alt='')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput{
  @extend .ec-input;
  display: inline-block;
  input{
    display: inline-block;
    text-align: left;
    width: auto;
    max-width: 9em;
    font-size: 16px;
  }
  // span{
  //   display: inline-block;
  //   padding: 0 5px 0 3px;
  //   margin-left:5px;
  // }
}
.ec-zipInputHelp {
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin: 0 0 3px 10px;
  vertical-align: middle;
  line-height: 0;
  .ec-zipInputHelp__icon {
    display: block;
    // margin-top: -10px;
    width:20px;
    height:20px;
    background: #004e56;
    border-radius: 50%;
    font-size: 13px;
    position: relative;
    // top: -8px;

  .ec-icon {
    height: 100%;
    width: 100%;
  }
  .ec-icon img{
      width: 12px;
      height: 12px;
      position: relative;
      left: 4px;
      top: 4px;
    }
  }
  // span {
  //   margin-left: 8px;
  //   display: inline-block;
  //   color: #0092C4;
  //   vertical-align: 3px;
  // }

  a {
    color: #004e56;
    margin: 0 0 0 5px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
.ec-zipAuto {
  margin-bottom: 16px;
  .ec-inlineBtn {
    font-weight: normal;
  }
}
/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput{
  @extend .ec-input;
  input {
    max-width: 12em;
    text-align: left;
  }
}
